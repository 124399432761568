import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Carousel from "../components/carousel"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Styles from "./index.module.css"

import gifLoading from "../images/loading.gif"
import dog from "../images/dog703.jpg"

const Spinner = () => (
  <div style={{ height: "100vh", display: "grid" }}>
    <img className="spin-img" src={gifLoading} alt="Loading..." />
  </div>
)
const Err = () => (
  <div style={{ backgroundColor: "lightcoral", padding: "0 20px" }}>
    <h4>Oops something went wrong please try again later!!</h4>
  </div>
)

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      photo: "",
      file: "",
      email: "",
      costume: "Cowboy",
      loading: false,
      error: false,
      imgStr: "",
      orientation: -1,
      timeStamp: 0,
      emailSent: false,
    }
  }

  componentDidMount() {
    this.imgToDataURL()
  }

  updateCostume = type => {
    this.setState({ costume: type })
  }

  getOrientation(file, callback) {
    var reader = new FileReader()
    reader.onload = function(e) {
      var view = new DataView(e.target.result)
      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2)
      }
      var length = view.byteLength,
        offset = 2
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
        var marker = view.getUint16(offset, false)
        offset += 2
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1)
          }

          var little = view.getUint16((offset += 6), false) === 0x4949
          offset += view.getUint32(offset + 4, little)
          var tags = view.getUint16(offset, little)
          offset += 2
          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little))
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break
        } else {
          offset += view.getUint16(offset, false)
        }
      }
      return callback(-1)
    }
    reader.readAsArrayBuffer(file)
  }

  imgToDataURL = () => {
    const c = document.createElement("canvas")
    const img = document.createElement("img")
    img.src = dog
    img.onload = (_ => {
      c.height = img.naturalHeight
      c.width = img.naturalWidth
      const ctx = c.getContext("2d")
      ctx.drawImage(img, 0, 0, c.width, c.height)
      const base64String = c.toDataURL()
      this.setState({ photo: base64String })
    }).bind(this)
  }

  handelChange = event => {
    const { name, value, files } = event.target
    if (name === "file") {
      const file = files[0]
      if (file) {
        this.getOrientation(file, orientation => {
          this.setState({ orientation: orientation })
        })
        // console.log(file)
        const reader = new FileReader(file)
        reader.readAsDataURL(file)
        reader.onload = () => {
          // set image and base64'd image data in component state
          this.setState({
            photo: reader.result.toString(),
            file,
          })
        }
      }
    } else {
      this.setState(state => ({ ...state, [name]: value }))
    }
  }
  sendEmail = async event => {
    event.preventDefault()
    this.setState({ loading: true, error: false })
    const requestOptions = {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
        timeStamp: this.state.timeStamp,
      }),
    }
    try {
      await fetch(`https://moshtest.com/addEmail`, requestOptions)
      this.setState({ emailSent: true })
    } catch (error) {
      this.setState({ error: true })
    }
    this.setState({ loading: false })
  }

  handleSubmit = async event => {
    event.preventDefault()
    window.scrollTo(0, 0)

    if (typeof window.ga === 'function') {
      window.gtag('event', 'Generate portrait AB', {
        eventCategory: 'portraitAB',
        eventAction: 'generateAB',
        eventLabel: 'submit',
        eventValue: 1,
      });
    }

    this.setState({ loading: true, error: false })
    const requestOptions = {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        photo: this.state.photo.split("base64,")[1],
        costume: this.state.costume && this.state.costume.toLowerCase(),
        email: this.state.email,
        orientation: this.state.orientation,
        timeStamp: new Date().getTime(),
      }),
    }
    // alert(` orientation: ${this.state.orientation}`)
    try {
      const fetchResponse = await fetch(
        `https://moshtest.com/upload`,
        requestOptions
      )
      const { data, timeStamp, email } = await fetchResponse.json()
      this.setState({ emailSent: email ? true : false })
      this.setState({ imgStr: data || "" })
      this.setState({ timeStamp: timeStamp })
    } catch (e) {
      this.setState({ error: true })
    }
    this.setState({ loading: false })
  }

  render() {
    return (
      <Layout>
        <SEO title="Paws &amp; Tails AI" />

        <div className="lg:text-lg container mt-40">
          <h1 className="montserrat text-xl md:text-3xl font-semibold">
            Pet Portrait Generator
          </h1>
          <h2 className="text-lg md:text-xl my-1 block font-semibold">
            Free AI pet portrait creation
          </h2>
          <hr className="text-lg md:text-xl my-4" />
          <div className="flex flex-wrap">
            <div className=" w-full items-center mt-5">
              {this.state.imgStr.length === 0 && !this.state.loading && (
                <>
                  <h3 className="montserrat text-lg md:text-xl my-1 block font-semibold text-orange-600">
                    Step 1:
                  </h3>
                  <h3 className="montserrat text-xl md:text-2xl my-1 block font-semibold">
                    Choose a Costume:
                  </h3>
                  <form onSubmit={this.handleSubmit}>
                    <div className="w-full md:w-1/2">
                      <Carousel updateSelection={this.updateCostume} />
                    </div>

                    <label className=" my-4 block" htmlFor="file">
                      {this.state.error && <Err />}
                      <h3 className="montserrat text-lg md:text-xl my-1 block font-semibold text-orange-600">
                        Step 2:
                      </h3>
                      <h3 className="montserrat text-xl md:text-2xl my-1 block font-semibold">
                        Pet photo:
                      </h3>
                      <input
                        id="file_upload"
                        className=" my-2 block text-lg "
                        type="file"
                        name="file"
                        onChange={this.handelChange}
                      />
                      <label htmlFor="file_upload">
                        <h2 className="text-xl font-semibold">
                          Use the image below or{" "}
                          <span className="text-blue-700">
                            click here to upload your own
                          </span>
                          .
                        </h2>
                        <img
                          src={this.state.photo}
                          alt="upload pet"
                          className="text-center md:w-1/2 w-full"
                        />
                      </label>
                    </label>

                    <label htmlFor="email" className=" my-4 block">
                      <h3 className="montserrat text-xl md:text-2xl my-1 block font-semibold">
                        Email:
                      </h3>
                      <input
                        className=" my-2 block text-xl p-1  border-gray-600 border-solid border-2 rounded"
                        type="email"
                        name="email"
                        value={this.state.email}
                        id="email"
                        onChange={this.handelChange}
                      />
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        style={{ marginRight: "20px" }}
                        required
                      />
                      <span>
                        I agree to the{" "}
                        <AniLink
                          cover
                          bg="#fff"
                          to="terms-and-conditions"
                          className="text-blue-700"
                        >
                          terms
                        </AniLink>{" "}
                        of service
                      </span>
                    </label>
                    <br />
                    <button
                      aria-label="Create Portrait"
                      className="my-4 text-xl bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                      type="submit"
                    >
                      Generate Portrait
                    </button>
                  </form>
                </>
              )}
              {this.state.loading && !this.state.error && <Spinner />}
              {this.state.imgStr.length > 0 &&
                !this.state.loading &&
                !this.state.error && (
                  <div>
                    <h3 className="text-xl md:text-2xl my-2 block font-semibold">
                      Your portrait
                    </h3>

                    <img
                      title="Enhanced AI Products"
                      src={`data:image/png;base64,${this.state.imgStr}`}
                      alt="Your AI generated pet portrait"
                      />
                    <button
                      onClick={() => window.location.reload()}
                      className="my-4 text-xl bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                      >
                      Make a new portrait
                    </button>
                    {!this.state.emailSent && (
                      <form onSubmit={this.sendEmail}>
                        <label htmlFor="email" className=" my-4 block">
                          <h3 className="montserrat text-xl md:text-2xl my-1 block font-semibold">
                            Email:
                          </h3>
                          <input
                            className=" my-2 block text-xl p-1  border-gray-600 border-solid border-2 rounded"
                            type="email"
                            name="email"
                            value={this.state.email}
                            id="email"
                            onChange={this.handelChange}
                          />
                          <button
                            aria-label="Create Portrait"
                            className="my-4 text-xl bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                            type="submit"
                          >
                            Share Email
                          </button>
                          <br />
                          <span>
                            <small>
                              {" "}
                              Please consider sharing your email to help us
                              improve our service to you.
                            </small>
                          </span>
                        </label>
                      </form>
                    )}
                  </div>
                )}
              <div>
                <hr className="text-lg md:text-xl my-4" />
                <div
                  className={`${Styles.tips} w-full items-center  mt-3 flex flex-wrap`}
                >
                  <h2 className="montserrat text-xl md:text-2xl font-semibold my-2">
                    Tips for creating a great portrait:
                  </h2>
                  <ul className="my-2 pl-6">
                    <li>Only have one pet in your photo.</li>
                    <li>Take a photo using your phone.</li>
                    <li>Close up shots work best.</li>
                    <li>
                      Make sure your pets head is facing the same direction as
                      the costume you choose.
                    </li>
                    <li>Upload a HD image of your pet.</li>
                    <li>
                      Paws &amp; Tails AI currently only supports photos of dogs
                      &amp; cats, but we're working to support even more
                      animals.
                    </li>
                  </ul>
                </div>
                <h3 className="text-xl md:text-2xl my-2 block font-semibold">
                  We're currently in our beta testing phase, feedback is much
                  appreciated!
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
